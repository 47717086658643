<template>
  <div class="financial-overview-report">
    <!-- START GRADIENT HEADER -->
    <b-container class="main-gradient light" fluid>

      <!-- START BREADCRUMB, TITLE, TABS WRAPPER -->
      <b-container class="main-gradient-header-wrapper pb-5">

        <b-row class="breadcrumb-wrapper" no-gutters>
          <b-col no-gutters cols="12">
            <b-breadcrumb :items="breadcrumbItems" class="dark"></b-breadcrumb>
          </b-col>
          <b-col cols="12">
            <b-col cols="12" sm="7" md="8" lg="9" class="pull-left pl-0 pr-0 pr-md-2">
              <h1 class="text-left">{{ $t(translationPath + 'report_title') }}</h1>
            </b-col>
            <b-col cols="12" sm="5" md="4" lg="3" class="pull-left pr-0 pl-0 pl-md-2">
              <period-selector
                v-if="isPeriodSelectorShown"
                :show-predefined-periods="false"
                :show-resolution="true"
                :allowed-resolutions="['day', 'month', 'year']"
                :default-start-date="period_start"
                :default-end-date="period_end"
                @period-selected="hanldeChangeDate"
              />
            </b-col>
          </b-col>
        </b-row>

        <b-row class="main-gradient-tab-menu-wrapper mb-2" no-gutters>
          <b-col no-gutters>
            <financial-overview-report-tabs :activeTab="activeTab" @tab-switched="onTabSwitched" ref="Tabmenu"/>
          </b-col>
        </b-row>

      </b-container>
      <!-- END BREADCRUMB, TITLE, TABS WRAPPER -->

      <!-- START CHART WRAPPER -->
      <b-row class="main-gradient-chart-wrapper">
        <div class="pb-5" style="width:100%;height:500px;">

        </div>
      </b-row>
      <!-- END CHART WRAPPER -->

    </b-container>
    <!-- END GRADIENT HEADER -->

    <!-- START PAGE WRAPPER -->
    <b-container class="main-content-wrapper pt-0 pb-0">
      <financial-overview-report-income-table v-if="activeTab === 'incomes'"></financial-overview-report-income-table>
    </b-container>
    <!-- END PAGE WRAPPER -->

  </div>
</template>

<script>
import { mapState } from 'vuex'
import titleMixins from '@/mixins/title'
import FinancialOverviewReportTabs from './FinancialOverviewReportTabs'
import PeriodSelector from '@/components/common/PeriodSelector'
import axios from 'axios'
import FinancialOverviewReportIncomeTable from './FinancialOverviewReportIncomeTable'

export default {
  name: 'FinancialOverviewReport',
  mixins: [titleMixins],
  components: {
    FinancialOverviewReportTabs,
    FinancialOverviewReportIncomeTable,
    PeriodSelector
  },
  filters: {},
  data () {
    return {
      activeTab: null,
      apiData: {
        income: null,
        expense: null
      },
      busyState: false,
      translationPath: 'reports.other.financial-overview.',
      period_start: null,
      period_end: null,
      isPeriodSelectorShown: false
    }
  },
  async created () {
    this.period_start = new Date(new Date().getFullYear(), new Date().getMonth() - 10, 1).toISOString().split('T')[0]
    this.period_end = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1).toISOString().split('T')[0]
    this.isPeriodSelectorShown = true
  },
  computed: {
    ...mapState('user', ['currentUser', 'currentCOA']),
    breadcrumbItems () {
      return [
        { text: this.$t('reports._common.reports_and_insights'), to: '/reports' },
        { text: '', active: true }
      ]
    },
    titleMeta () {
      return this.$t(this.translationPath + 'report_title')
    }
  },
  methods: {
    hanldeChangeDate (eventData) {
      this.period_start = eventData.sDateStart
      this.period_end = eventData.sDateEnd
    },
    onTabSwitched (newtab) {
      this.activeTab = newtab
      if (newtab !== this.$route.params.tab) {
        this.$router.push({ name: 'reports-other-financial-overview', params: { tab: newtab } })
      }
    },
    async loadData () {
      this.busyState = true
    },
    changeTab (tab) {
      if (['summary', 'incomes', 'expenses', 'assets', 'liabilities', 'budget'].indexOf(this.$route.params.tab) !== -1) {
        this.$refs.Tabmenu.changeTab(tab)
      }
    },
    fetchDataFromApi (startDate, endDate) {
      this.busyState = true
      axios.get(`${process.env.VUE_APP_ROOT_API}/reports/budget/period/with/prognosis?start_date=${startDate}&end_date=${endDate}`)
        .then((res) => res.data.data)
        .then((res) => {
          const { income, expense, totals } = res
          this.apiData = {
            income: income,
            expense: expense,
            totals: totals || {
              actual_amount: 0,
              budget_amount: 0,
              diff_amount: 0,
              diff_percentage: 0,
              full_year_budget_amount: 0,
              full_year_budget_percentage_of_full_year: 0,
              full_year_prognosis: 0
            }
          }
          this.busyState = false
        })
        .catch((err) => {
          console.log(err)
          this.apiData = {
            income: null,
            expense: null,
            totals: null
          }
          this.busyState = false
        })
    }
  },
  watch: {
    currentCOA: {
      deep: true,
      handler () {
        this.loadData()
      }
    },
    titleMeta: {
      handler () {
        this.setPageTitle(this.titleMeta)
      },
      immediate: true
    },
    '$route.params.tab' () {
      this.changeTab(this.$route.params.tab)
    }
  },
  mounted () {
    this.changeTab(this.$route.params.tab)
    this.$watch(vm => [vm.period_start, vm.period_end], () => {
      if (this.period_start && this.period_end) {
        this.fetchDataFromApi(this.period_start, this.period_end)
      }
    }, {
      immediate: true, // run immediately
      deep: true // detects changes inside objects. not needed here, but maybe in other cases
    })
  }
}
</script>

<style lang="scss">
.financial-overview-report {
  width: 100%;
}
</style>
