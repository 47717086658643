<template>
  <div class="main-gradient-header-tab-menu">
    <b-row class="submenu-container dark justify-content-center" no-gutters>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="summary" :class="{selected: activeTab === 'summary'}"  @click="onTabClick">
          {{ $t(translationPath + 'summary') }}
        </b-link> <span class="main-gradient-header-tab-menu-divider mx-3">|</span>
      </b-col>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="incomes" :class="{selected: activeTab === 'incomes'}"  @click="onTabClick">
          {{ $t(translationPath + 'incomes') }}
        </b-link> <span class="main-gradient-header-tab-menu-divider mx-3">|</span>
      </b-col>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="expenses" :class="{selected: activeTab === 'expenses'}"  @click="onTabClick">
          {{ $t(translationPath + 'expenses') }}
        </b-link><span class="main-gradient-header-tab-menu-divider mx-3">|</span>
      </b-col>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="assets" :class="{selected: activeTab === 'assets'}"  @click="onTabClick">
          {{ $t(translationPath + 'assets') }}
        </b-link><span class="main-gradient-header-tab-menu-divider mx-3">|</span>
      </b-col>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="liabilities" :class="{selected: activeTab === 'liabilities'}"  @click="onTabClick">
          {{ $t(translationPath + 'liabilities') }}
        </b-link><span class="main-gradient-header-tab-menu-divider mx-3">|</span>
      </b-col>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="budget" :class="{selected: activeTab === 'budget'}"  @click="onTabClick">
          {{ $t(translationPath + 'budget') }}
        </b-link>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    active: {
      type: String,
      default: 'overview'
    }
  },
  name: 'FinancialOverviewReportTabs',
  data () {
    return {
      activeTab: null,
      translationPath: 'reports.other.financial-overview.tabs.'
    }
  },
  created () {
    this.activeTab = this.active
  },
  methods: {
    onTabClick (event) {
      this.activeTab = event.target.attributes.tab.value
      this.$emit('tab-switched', event.target.attributes.tab.value)
    },
    changeTab (tab) {
      this.activeTab = tab
      this.$emit('tab-switched', tab)
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/submenu.scss';
</style>
