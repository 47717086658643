<template>
  <div class="financial-overview-report-income-table-wrapper">

    <b-container fluid class="financial-overview-report-table-wrapper px-0 mt-4 settings-tab">
      <b-row no-gutters>
        <b-col>
          <b-card class="main-gradient-content-card borderlight pt-3 mx-xl-auto">
            <template v-slot:header>
              <h2>{{ $t(translationPath + 'title_accounts', { period_start: startDate, period_end: endDate}) }}</h2>
            </template>

            <div class="col-lg-8 pl-0" v-html="$t(translationPath + 'description_period_table_total')"></div>
            BAJS
          </b-card>
        </b-col>
      </b-row>
    </b-container>

  </div>
</template>

<script>
/* eslint-disable camelcase */
import { mapState } from 'vuex'
// import Loader from '@/components/common/Loader'
import formatAmount from '@/assets/filters/formatAmount'

export default {
  name: 'FinancialOverviewReportIncomeTable',
  components: {
    // Loader
  },
  filters: {
    formatAmount
  },
  props: {
    startDate: {
      type: String,
      default: null
    },
    endDate: {
      type: String,
      default: null
    },
    busyState: { type: Boolean, default: false },
    apiData: {
      type: Object,
      default: function () {
        return {
          income: null,
          expense: null,
          totals: {
            actual_amount: 0,
            budget_amount: 0,
            diff_amount: 0,
            diff_percentage: 0,
            full_year_budget_amount: 0,
            full_year_budget_percentage_of_full_year: 0,
            full_year_prognosis: 0
          }
        }
      }
    }
  },
  data () {
    return {
      translationPath: 'reports.other.financial-overview.incomes.'
    }
  },
  computed: {
    ...mapState('user', ['currentUser', 'currentCOA']),
    fieldValues () {
      return [
        { key: 'account_title', label: this.currentUser.strict_accounting_mode ? this.$t('common.account') : this.$t('common.category'), sortable: false, class: 'text-left' }
      ]
    },
    local () {
      return this.currentUser.default_lang
    }
  },
  methods: {
  },
  watch: {
    apiData: {
      handler: function (value) {

      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.financial-overview-report-income-table-wrapper{

}
</style>
